/*
 * @Author: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @Date: 2022-09-20 14:29:41
 * @LastEditors: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @LastEditTime: 2022-10-12 18:20:57
 * @FilePath: \admin-safety-examine\src\util\dialog.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Message } from "element-ui";

const messageOptions = { duration: 2500 };

export default {
  message: {
    success: (msg) => {
      Message.success({ ...messageOptions, message: msg });
    },
    error: (msg) => {
      Message.error({ ...messageOptions, message: msg });
    },
    warning: (msg) => {
      Message.warning({ ...messageOptions, message: msg });
    },
    info: (msg) => {
      Message.info({ ...messageOptions, message: msg });
    },
  },
};
