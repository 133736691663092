/*
 * @Author: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @Date: 2022-10-08 11:52:16
 * @LastEditors: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @LastEditTime: 2022-10-12 14:41:25
 * @FilePath: \admin-safety-examine\src\component\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    roles: [],
    userInfo: {},
  },
  mutations: {
    SET_ROLES(state, data) {
      state.roles = data;
    },
    CLEAR_ROLES(state) {
      state.roles = [];
    },
    SAVE_USERS(state, data) {
      state.userInfo = data;
    },
    CLEAR_USERS(state) {
      state.userInfo = {};
    },
  },
});
export default store;
