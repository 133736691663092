/*
 * @Author: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @Date: 2022-09-29 13:37:31
 * @LastEditors: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @LastEditTime: 2022-10-12 16:34:24
 * @FilePath: \admin-safety-examine\src\api\fkApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/util/request";

const url = "v1/admin/mycenter";

export default {
  // 获取表格列表
  getArray: (params) => {
    return request.get(`${url}/getLoginUser`, params);
  },
  modify: (params) => {
    return request.post(`${url}/modify`, params);
  },
  updatePwd: (params) => {
    return request.post(`${url}/updatePwd`, null, null, params);
  },
};
