<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locale: {
        zh_cn: "zh-CN",
        en_us: "en-US",
      },
    };
  },

  created: function() {},

  mounted: function() {},

  // 方法
  methods: {
    // 切换国际化
    switchLocale() {
      this.$i18n.locale = this.locale.en_us;
    },
  },
};
</script>

<style lang="css">
@import "../assets/css/reset.css";
@import "../assets/css/border.css";
@import "../assets/theme/color-dark.css";     /*深色主题*/
</style>
