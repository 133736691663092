/*
 * @Author: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @Date: 2022-09-20 14:29:41
 * @LastEditors: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @LastEditTime: 2022-10-08 12:08:29
 * @FilePath: \admin-safety-examine\src\page\index\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueWechatTitle from "vue-wechat-title";
import router from "@/component/route/index";
import i18n from "@/i18n/i18n";
import App from "@/component/App.vue";
import "./element";
import "@/assets/css/common.less";
import store from "@/component/store/index";
Vue.config.productionTip = false;
Vue.use(VueWechatTitle);

new Vue({
  i18n,
  store,
  router: router,
  render: (h) => h(App),
}).$mount("#app");
