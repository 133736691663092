import Enum from "@/enum";

// api错误码
export const ResultCodeEnum = {
  success: new Enum("OK", "操作成功"),
  fail: new Enum("BUSINESS_FAIL", "操作失败"),
  inputError: new Enum("BAD_USER_INPUT", "参数错误"),
  error: new Enum("INTERNAL_SERVER_ERROR", "系统繁忙，请稍后再试"),
  unauthenticated: new Enum("UNAUTHENTICATED", "未登录"),
  forbidden: new Enum("FORBIDDEN", "未授权"),
  accountDisabled: new Enum("ACCOUNT_DISABLED", "账号已被禁用"),
  kickout: new Enum("KICKOUT", "被踢出登录"),
};

// 分页控件配置信息
export const PaginationConfig = {
  layout: "total, sizes, prev, pager, next, jumper", // 组件布局，子组件名用逗号分隔
  pageSizes: [10, 20, 30, 40], // 每页显示个数选择器的选项设置
  pageSize: 10, // 每页显示条目个数
};

// 详情页表单label宽度
export const FormLabelWidth = "350px";
