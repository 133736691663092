import enElementLocale from "element-ui/lib/locale/lang/en";

export default {
  "en-US": {
    copywriting: {
      company: "Company",
      copyright: "@Copyright",
    },
    // 提示语
    message: {
      hello: "Hello, World.",
    },
    // element
    ...enElementLocale,
  },
};
