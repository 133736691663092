import zhElementLocale from "element-ui/lib/locale/lang/zh-CN";

export default {
  "zh-CN": {
    copywriting: {
      company: "公司名称",
      copyright: "Power By 公司名称",
    },
    // 提示语
    message: {
      hello: "你好, 中国.",
    },
    // element
    ...zhElementLocale,
  },
};
