/*
 * @Author: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @Date: 2022-09-14 10:57:56
 * @LastEditors: 小谢 9448521+XXSXYC@user.noreply.gitee.com
 * @LastEditTime: 2022-10-17 09:20:32
 * @FilePath: \admin-safety-examine\src\api\urls.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import config from "@/config";

// 不适合放到业务请求模块的url
export default {
  upload: `${config.apiPrefix()}v1/base/upload/uploadImage`, // 上传
  imgCode: `${config.apiPrefix()}v1/base/graphCode/getImage?`,
  importPatrolList: `${config.apiPrefix()}v1/admin/patrol/plan/import`, // 上传
  importEnterprise: `${config.apiPrefix()}v1/admin/patrol/enterprise/import`, // 上传
  getPatroltemplate: `${config.apiPrefix()}v1/admin/patrol/plan/downloadTemplate`, // 导入模板
  getEnterprisetemplate: `${config.apiPrefix()}v1/admin/patrol/enterprise/downloadTemplate`, // 导入模板
};
