import Vue from "vue";
import VueRouter from "vue-router";
import { constantRouter, filterRouter } from "./routes";
// 1.为了达到路由懒加载的效果, 引用组件需要使用以下方式
// const Other = () => import("../other/index/index.vue");
// 2.首屏需要加载的组件直接引入
// import Home from "../home/index/index.vue";
// import User from "../upms/user/index/index.vue";
import fkApi from "@/api/fkApi";
import store from "@/component/store/index";
Vue.use(VueRouter);

export const router = new VueRouter({
  routes: constantRouter,
});

export const clearRouter = () => {
  const defaultRouter = new VueRouter({
    routes: constantRouter,
  });
  // console.log("matcher", router.matcher, defaultRouter.matcher);
  router.matcher = defaultRouter.matcher;
};

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

const whiteList = ["login"];
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    const head = document.getElementsByTagName("head");
    const meta = document.createElement("meta");
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (sessionStorage.getItem("loginFlag")) {
    if (to.name === "login") {
      next({ path: "/" });
    } else {
      if (!store.state.roles.length) {
        fkApi.getArray().then((res) => {
          if (res.code === "OK") {
            const rList = res.data.permissionCodes;
            store.commit("SAVE_USERS", res.data);
            store.commit("SET_ROLES", rList.length ? rList : ["nothing"]);
            // sessionStorage.setItem("initRouterList", JSON.stringify(rList));
            router.addRoutes(filterRouter(rList));
            next({ ...to, replace: true });
          } else {
            sessionStorage.removeItem("loginFlag");
            next({ name: "login" });
          }
        });
      } else {
        next();
      }
    }
  } else {
    if (!whiteList.includes(to.name)) {
      next({ name: "login" });
    } else {
      next();
    }
  }
});

export default router;
