export const constantRouter = [
  {
    path: "/",
    redirect: "/statisticalAnalysis",
    component: () => import("../common/Home.vue"),
    children: [
      {
        name: "StatisticalAnalysis",
        path: "/statisticalAnalysis",
        component: () => import("../statisticalAnalysis/statisticalAnalysis"),
        meta: { title: "统计分析" },
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/component/login/index/index.vue"),
    name: "login",
  },
  {
    path: "/404",
    component: () => import("@/component/common/404.vue"),
    name: "404",
  },
];
export const asyncRouters = [
  {
    path: "/",
    component: () => import("../common/Home.vue"),
    meta: { title: "home", key: "admin", code: "common" },
    children: [
      // {
      //   path: "/upms/user/index",
      //   component: User,
      //   meta: { title: "管理员" },
      // },
      {
        path: "/upms/user/index/add",
        component: () => import("../upms/user/detail/index.vue"),
        meta: { title: "新增管理员", code: "upms" },
      },
      {
        path: "/upms/user/index/edit/:id",
        component: () => import("../upms/user/detail/index.vue"),
        meta: { title: "编辑管理员", code: "upms" },
      },
      // {
      //   path: "/member/index",
      //   component: () => import("../member/index/index.vue"),
      //   meta: { title: "会员管理" },
      // },
      {
        name: "Role",
        path: "/upms/role/index",
        component: () => import("../upms/role/index/index.vue"),
        meta: { title: "角色管理", code: "upms" },
      },
      {
        name: "User",
        path: "/upms/user/index",
        component: () => import("../upms/user/index/index.vue"),
        meta: { title: "用户管理", code: "upms" },
      },
      {
        name: "InspectionPlan",
        path: "/patrolinspectors/inspectionPlan",
        component: () => import("../patrolInspectionPlan/index/inspectionPlan.vue"),
        meta: { title: "巡检计划", code: "inspectionPlan" },
      },
      {
        name: "PlanDetails",
        path: "/patrolinspectors/planDetails",
        component: () => import("../patrolInspectionPlan/planDetails.vue"),
        meta: { title: "计划详情", code: "inspectionPlan", isBack: false },
        beforeEnter: (to, from, next) => {
          if (from.path === "/patrolinspectors/addPatrolList") {
            to.meta.isBack = true;
          } else {
            to.meta.isBack = false;
          }
          next();
        },
      },
      {
        path: "/patrolinspectors/addPatrolList",
        component: () => import("../patrolInspectionPlan/addPatrolList.vue"),
        meta: { title: "添加巡检项", code: "inspectionPlan" },
        beforeEnter: (to, from, next) => {
          if (to.query.rowData) {
            to.meta.title = "编辑巡检项";
          } else {
            to.meta.title = "添加巡检项";
          }
          next();
        },
      },
      {
        name: "InspectionReport",
        path: "/patrolinspectors/inspectionReport",
        component: () => import("../patrolInspectionPlan/inspectionReport.vue"),
        meta: { title: "巡检报告", code: "inspectionPlan" },
      },
      {
        name: "InspectionDetail",
        path: "/patrolinspectors/inspectionDetail",
        component: () => import("../patrolInspectionPlan/inspectionDetail.vue"),
        meta: { title: "巡检详情", code: "inspectionPlan" },
      },
      {
        name: "PatrolItemHandle",
        path: "/patrolItemHandle",
        component: () => import("../patrolInspectionPlan/patrolItemHandle.vue"),
        meta: { title: "巡检项处理", code: "inspectionPlan" },
      },
      {
        name: "Siteclassifymanage",
        path: "/patrolInspection/siteclassifymanage",
        component: () => import("../patrolInspection/index/siteclassifymanage.vue"),
        meta: { title: "场所分类管理", code: "patrolInspection" },
        // code: "siteclassifymanage",
      },
      {
        name: "Patrolclassifymanage",
        path: "/patrolInspection/patrolclassifymanage",
        component: () => import("../patrolInspection/index/patrolclassifymanage.vue"),
        meta: { title: "巡检分类管理", code: "patrolInspection" },
        // code: "patrolclassifymanage",
      },
      {
        name: "PatrolDetails",
        path: "/patrolInspection/patrolDetails",
        component: () => import("../patrolInspection/index/patrolDetails.vue"),
        meta: { title: "巡检项详情", code: "patrolInspection" },
      },
      {
        name: "InspectorMaintenance",
        path: "/patrolinspectors/inspectorMaintenance",
        component: () => import("../patrolinspectors/index/inspectorMaintenance"),
        meta: { title: "巡检人员维护", code: "patrolinspectors" },
      },
      {
        path: "/patrolinspectors/inspectorMaintenance/add",
        component: () => import("../patrolinspectors/index/addMembers"),
        meta: { code: "patrolinspectors" },
        beforeEnter: (to, from, next) => {
          if (to.query.isadd) {
            to.meta.title = "添加巡检人员";
          } else {
            to.meta.title = "编辑巡检人员";
          }
          next();
        },
      },
      {
        name: "NewsClassify",
        path: "/newsManagement/newsClassify",
        component: () => import("../newsManagement/newsClassify"),
        meta: { title: "分类管理", code: "newsManage" },
      },
      {
        name: "NewsManagement",
        path: "/newsManagement/newsManagement",
        component: () => import("../newsManagement/newsManagement"),
        meta: { title: "信息管理", code: "newsManage" },
      },
      {
        path: "/newsManagement/addNews",
        component: () => import("../newsManagement/addNews"),
        meta: { title: "添加新闻", code: "newsManage" },
      },
      {
        name: "Management",
        path: "/businessManage/management",
        component: () => import("../businessManage/management"),
        meta: { title: "企业管理", code: "businessManage" },
      },
      {
        name: "PatrolHistory",
        path: "/businessManage/patrolHistory",
        component: () => import("../businessManage/patrolHistory"),
        meta: { title: "企业巡检历史", code: "businessManage" },
      },
      {
        name: "RectificationDetails",
        path: "/rectificationDetails",
        component: () => import("../businessManage/rectificationDetails"),
        meta: { title: "整改详情", code: "businessManage" },
      },
      {
        name: "ItemsHandle",
        path: "/itemsHandle",
        component: () => import("../businessManage/itemsHandle"),
        meta: { title: "整改项处理", code: "businessManage" },
      },
      {
        path: "/addEnterprise",
        component: () => import("../businessManage/addEnterprise"),
        meta: { title: "添加企业", code: "businessManage" },
        beforeEnter: (to, from, next) => {
          if (to.query.isadd) {
            to.meta.title = "添加企业";
          } else {
            to.meta.title = "编辑企业";
          }
          next();
        },
      },
      {
        name: "WorkLogs",
        path: "/workLogs",
        component: () => import("../workLogs/workLogs"),
        meta: { title: "工作日志", code: "workLogs" },
      },
      {
        name: "OverdueWarning",
        path: "/overdueWarning",
        component: () => import("../overdueWarning/overdueWarning"),
        meta: { title: "逾期预警", code: "overdueWarning" },
      },
      {
        path: "/accountInfo",
        component: () => import("../login/accountInfo"),
        meta: { title: "账号信息", code: "accountInfo" },
      },
    ],
  },
  {
    path: "*",
    meta: { code: "common" },
    redirect: "/404",
  },
];

export const filterRouter = (roles, routes = asyncRouters) => {
  const res = [];
  for (let i = 0; i < routes.length; i++) {
    if (roles.includes(routes[i].meta.code) || routes[i].meta.code === "common") {
      // console.log(routes[i]);
      if (routes[i].children) {
        res.push({ ...routes[i], children: filterRouter(roles, routes[i].children) });
      } else {
        res.push({ ...routes[i] });
      }
    }
  }
  return res;
};
