// 全局变量 __ENV__, __MOCK__, __API_PREFIX__

const config = {
  apiPrefix: () => {
    // 绝对路径 http:// https:// 和 相对路径 /
    if (/^http[s]{0,1}:\/\//.test(__API_PREFIX__) || /^\//.test(__API_PREFIX__)) {
      return __API_PREFIX__;
    }
    throw new Error("API前缀配置有误，仅支持以 http:// https:// 或 / 开头的路径");
  },
};

export default config;
