import axios from "axios";
import qs from "qs";
import dialog from "@/util/dialog";
import { ResultCodeEnum } from "@/constants";
import urls from "@/api/urls";
import config from "@/config";

// 控制台打印模拟数据
import { logMockAxios } from "../../mock/core/logMock";
// 引入mock数据
if (__MOCK__) {
  require("../../mock/core");
}

const instance = axios.create({
  baseURL: config.apiPrefix(),
  withCredentials: true,
  headers: {
    "Content-Type": "application/json;charset=UTF-8", // "application/x-www-form-urlencoded;charset=UTF-8",
  } /* ,
  transformRequest: [
    function(data) {
      let ret = "";
      if (data) {
        ret = qs.stringify(data);
        return ret;
      }
    },
  ], */,
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    // 控制台打印模拟数据
    logMockAxios(response);

    const data = response.data;
    if (typeof data !== "object") {
      throw new Error("响应数据必须为object类型(typeof)");
    }

    if (data.code === ResultCodeEnum.unauthenticated.code) {
      // // 未登录, 记录当前访问路径
      sessionStorage.removeItem("loginFlag");
      const path = window.location.href.split("#")[0];
      dialog.message.error(data.errorMsg ? data.errorMsg : "操作失败");
      window.location.href = `${path}#/login`;
    } else if (data.code === ResultCodeEnum.accountDisabled.code) {
      dialog.message.error(data.errorMsg ? data.errorMsg : "操作失败");
      // 账号被禁用
    } else if (data.code === ResultCodeEnum.forbidden.code) {
      dialog.message.error(data.errorMsg ? data.errorMsg : "操作失败");
      // 权限不足
    } else if (data.code === ResultCodeEnum.inputError.code) {
      // 参数错误
      dialog.message.warning(data.errorMsg ? data.errorMsg : "参数错误");
    } else if (data.code === ResultCodeEnum.fail.code) {
      // 其他错误
      dialog.message.error(data.errorMsg ? data.errorMsg : "操作失败");
    }
    return data;
  },
  (error) => {
    dialog.message.error("网络请求失败，请重试");
    return Promise.reject(error);
  }
);

export default {
  get: (url, data) => {
    return instance.get(url, data ? { params: data } : {});
  },
  post: (url, data, headers, params) => {
    return instance.post(url, data, { headers, params });
  },
  // paramsPost: (url, config) => {
  //   // config => {}
  //   return instance.post(url, null, config);
  // },
  put: (url, data, headers, params) => {
    return instance.put(url, data, { headers, params });
  },
  delete: (url, data, headers) => {
    return instance.delete(url, { ...(data ? { params: data } : {}), headers });
  },
  patch: (url, data, headers) => {
    return instance.patch(url, data, { headers });
  },
  upload: function(param) {
    return new Promise((resolve, reject) => {
      axios
        .post(urls.upload, param, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response.data);
        });
    });
  },
};
