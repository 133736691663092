import R from "ramda";

/**
 * 枚举类定义
 * 示例:
 * const ResultCodeEnum = {
 *  success: new Enum("OK", "操作成功"),
 *  fail: new Enum("BUSINESS_ERROR", "操作失败"),
 * };
 */
export default class Enum {
  /**
   * @param code 枚举编码(值)
   * @param desc 说明
   */
  constructor(code, desc) {
    if (R.isNil(code)) {
      throw new Error("code不能为空");
    }
    this.code = code;
    this.desc = desc;
  }

  equals(enumObj) {
    if (R.isNil(enumObj)) {
      return false;
    }
    if (enumObj instanceof Enum) {
      return this.code === enumObj.code;
    }
    if (typeof enumObj === "string") {
      return this.code === enumObj;
    }
    if (typeof enumObj === "number") {
      return this.code === enumObj.toString();
    }
    return false;
  }

  static toArray(ObjectEnum) {
    const arr = [];
    for (const field of Object.values(ObjectEnum)) {
      if (field instanceof Enum) {
        arr.push(field);
      }
    }
    return arr;
  }
}
