import Vue from "vue";
import VueI18n from "vue-i18n";
import ElementLocale from "element-ui/lib/locale";
import messages from "./locale";

// 文档 https://kazupon.github.io/vue-i18n/zh/guide/formatting.html

// 默认locale
const LOCALE_DEFAULT = "zh-CN";

// 当地locale
const localLocale = () => {
  // 从缓存获取
  return localStorage.locale;
};

Vue.use(VueI18n);
const i18n = new VueI18n({
  // 支持动态修改locale切换不同语言
  locale: localLocale() || LOCALE_DEFAULT,
  fallbackLocale: LOCALE_DEFAULT,
  messages: messages,
});

// Element桥接VueI18n插件
// 如果使用其它语言，默认情况下中文语言包依旧是被引入的 ??? 可以使用 webpack 的 NormalModuleReplacementPlugin 替换默认语言包 ???
// https://element.eleme.cn/#/zh-CN/component/i18n#an-xu-jia-zai-li-ding-zhi-i18n
ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;
